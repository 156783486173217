<template>
  <div id="app" class="page-container">
    <md-app md-waterfall md-mode="fixed" :md-scrollbar="false">
      <md-app-toolbar class="md-primary">
        <div class="md-toolbar-row md-toolbar-offset">
          <span class="md-headline">볼링대회 대시보드</span>
        </div>
      </md-app-toolbar>
      <md-app-content>
        <form novalidate class="md-layout" @submit.prevent="splitting">
          <md-card>
            <md-card-header class="md-title">
              참가자 선택
            </md-card-header>
            <md-card-content>
              <md-chip v-for="(name, index) in users" :key="`user-${index}`" :class="{'md-primary':absentee.indexOf(name)<0}" @click="toggleAbsentee(name)">{{ name }}</md-chip>
            </md-card-content>
            <md-divider></md-divider>
            <md-card-header class="md-subheading">
              필수 경쟁자 선택
            </md-card-header>
            <md-card-content>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <md-select v-model="match1" name="match1" id="match1" placeholder="참가자 1 선택">
                      <md-option v-for="(user, matchIndex) in match1Users" :key="`match1-${matchIndex}`" :value="user">{{ user }}</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <md-select v-model="match2" name="match2" id="match2" placeholder="참가자 2 선택">
                      <md-option v-for="(user, matchIndex) in match2Users" :key="`match2-${matchIndex}`" :value="user">{{ user }}</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
        <div class="md-layout md-alignment-center" style="margin:8px 8px 0;">
          <md-button class="md-raised md-primary" @click="splitting">팀 섞기</md-button>
        </div>
        <div class="md-layout md-alignment-center" style="margin:8px 8px 0;">
          <div class="teams">
            <div class="team">
              <label>A</label>
              <md-chip v-for="(name, index) in team.A" ref='teamA' :key="`team_A-${index}`" class="team-a rullet loaded" v-html="rullet(name)"></md-chip>
            </div>
            <div class="team">
              <label>B</label>
              <md-chip v-for="(name, index) in team.B" ref='teamB' :key="`team_B-${index}`" class="team-b rullet loaded" v-html="rullet(name)"></md-chip>
            </div>
          </div>
        </div>
        <div v-if="buttonShow" class="md-layout md-alignment-center" style="margin:-4px 8px 0;">
          <md-button class="md-raised md-primary" @click="showBoard">팀 저장 후 점수 입력</md-button>
        </div>
        <md-card v-show="boardShow">
          <md-card-header>
            <md-card-header-text class="md-title" style="margin-top:3px; text-align: left">
              점수 입력
            </md-card-header-text>
            <md-button class="md-primary add-button" @click="addMatch">
              <span>현재 팀 매치 추가</span>
            </md-button>
          </md-card-header>
          <md-divider></md-divider>
          <md-card-content class="scores">
            <div class="md-layout md-gutter">

              <md-tabs :md-active-tab="tab" ref="tabs">
                <md-tab v-for="(match, matchIndex) in matches" :key="`match-${matchIndex}`" :id="match" :md-label="matchIndex+1">
                  <div class="md-title">{{matchDate(match)}}</div>
                  <div class="md-layout md-gutter">

                    <template v-for="(user, userIndex) in inputUsers(match)">
                      <div class="field-wrap" :key="`score-${userIndex}`" v-if="teams[match]" :class="{'team-a':teams[match]['A'].indexOf(user)>-1,'team-b':teams[match]['B'].indexOf(user)>-1}">
                        <md-field class="md-headline">
                          <label @click.prevent="viewReport(user)">{{user}}</label>
                          <template v-if="score[match]">
                            <md-input v-model="score[match][user]" type="number" max="300" @blur="updateScore(match, user)" pattern="\d*"></md-input>
                          </template>
                        </md-field>
                      </div>
                    </template>

                  </div>
                  <div class="md-layout md-gutter" ref="report" v-if="!reRender">
                    <div class="summary-col">
                      <strong class="md-subheading team-title-a">A팀 통계</strong>
                      <strong class="md-subheading team-title-b">B팀 통계</strong>
                    </div>
                    <div class="summary-col">
                      <div class="summary-item">합계<span>{{ total(match, 'A') }}</span></div>
                      <div class="summary-item">평균<span>{{ average(match, 'A') | decimal }}</span></div>
                      <div class="summary-item">합계<span>{{ total(match, 'B') }}</span></div>
                      <div class="summary-item">평균<span>{{ average(match, 'B') | decimal }}</span></div>
                    </div>
                    <div class="summary-col">
                      <div class="summary-item half">전체합계<span>{{ total(match) }}</span></div>
                      <div class="summary-item half">전체평균<span>{{ average(match) | decimal }}</span></div>
                    </div>
                  </div>
                  <md-button class="md-icon-button md-dense sort" @click.prevent="inputSorted = !inputSorted"><md-icon>filter_list</md-icon></md-button>
                  <md-button class="md-icon-button md-dense delete" @click.prevent="deleteMatch(match)"><md-icon>delete_forever</md-icon></md-button>
                </md-tab>
              </md-tabs>

            </div>
          </md-card-content>
        </md-card>
      </md-app-content>
    </md-app>
    <md-snackbar md-position="center" :md-active.sync="showSnackbar" :md-duration="3000" md-persistent>
      <span>선발 완료!</span>
    </md-snackbar>
    <div class="modal" v-if="loading">
      <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <md-dialog :md-active.sync="report.show">
      <strong class="win-count" v-html="medals"></strong>
      <md-dialog-title>{{ report.user }}</md-dialog-title>
      <md-dialog-content>
        <dl class="average">
          <dt>
            Average
          </dt>
          <dd>
            {{ avg | decimal }}
          </dd>
        </dl>
        <apexcharts ref="chart" height="300" type="area" :options="report.option" :series="report.series"/>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="report.show = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { filter, uniq, remove, without, shuffle, zipObject, merge, reduce, findKey, sum, values, repeat } from 'lodash'
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";

import firebase from 'firebase/app'
import 'firebase/database'

import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'App',
  components: {
    apexcharts: VueApexCharts,
  },
  data: () => ({
    users: ["최다빈", "강민채", "이설희", "장성준", "최송희", "정수아", "주영남", "윤영서", "이유진", "신지연"],
    absentee: [],
    team: {
      A: [],
      B: []
    },
    match1: null,
    match2: null,
    showSnackbar: false,
    interval: null,
    buttonShow: false,
    boardShow: false,
    current: null,
    teams: {},
    score: {},
    matches: [],
    tab: 0,
    inputSorted: false,
    reRender: false,
    loading: true,
    avg: 0,
    report: {
      show: false,
      user: null,
      winCount: 0,
      series: [{
        name: 'Score',
        type: 'area',
        data: []
      }],
      option: {
        chart: {
          height: 500,
          stacked: true,
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '14px'
          },
          background: {
            padding: 4,
            fontFamily: 'NanumSquare',
            borderRadius: 3,
            borderWidth: 0
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        fill: {
          opacity: 0.2
        },
        yaxis: {
          show: false,
          min: 0,
          max: 200,
          tickAmount: 10,
          floating: true,
          forceNiceScale: true
        },
        xaxis: {
          categories: []
        },
        legend: {
          show: false
        },
        annotations: {
          position: 'back',
          yaxis: [{
            y: 0,
            borderColor: '#F33',
            borderWidth: 1,
            label: {
              show: true,
              borderWidth: 0,
            }
          }]
        }
      }
    },
  }),
  beforeMount() {
    console.log('init')
    this.initDatabase()
    this.getTeams()
  },
  mounted() {
    this.db.ref('score').on('value', (snapshot) => {
      if (snapshot.exists()) {
        this.score = merge(this.score, snapshot.val())

        this.$nextTick(() => {
          setTimeout(() => {
            // this.viewReport('이설희')
          }, 500)
        })

      }
    })
  },
  validations: {
    form: {}
  },
  computed: {
    sortedUsers() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return filter(this.users.sort(), (o) => {
        return this.absentee.indexOf(o) < 0
      })
    },
    match1Users() {
      return filter(this.sortedUsers, (o) => {
        return this.match2 !== o
      })
    },
    match2Users() {
      return filter(this.sortedUsers, (o) => {
        return this.match1 !== o
      })
    },
    medals() {
      const count = this.report.winCount
      return count > 5 ? `🏅<span>X ${count}</span>` : repeat('🏅', count)
    }
  },
  methods: {
    done() {
      this.showSnackbar = true
      this.buttonShow = true
      this.$nextTick(() => {
        document.querySelectorAll('.rullet').forEach(el => {
          el.classList.add('loaded')
          el.classList.remove('start')
        })
      })
    },
    splitting() {
      clearInterval(this.interval)
      this.showSnackbar = false
      this.team = {A: [], B: []}
      const entries = shuffle(without(this.users, ...this.absentee))

      entries.forEach((user, index) => {
        if ((index + 1) % 2 === 1) {
          this.team.A.push(user)
        } else {
          this.team.B.push(user)
        }
      })

      this.$nextTick(() => {
        if (this.match1 && this.match2) {
          if (entries.indexOf(this.match1) % 2 === entries.indexOf(this.match2) % 2) {
            this.splitting()
          } else {
            this.animate(this.done)
          }
        } else {
          this.animate(this.done)
        }
      })
    },
    toggleAbsentee(user) {
      if (this.absentee.indexOf(user) > -1) {
        remove(this.absentee, (o) => {
          return o === user
        })
        this.absentee = uniq(this.absentee)
      } else {
        this.absentee.push(user)
        this.absentee = uniq(this.absentee)
      }
    },
    initDatabase() {
      this.db = firebase.database()
      this.current = Date.now()
    },
    showBoard() {
      this.addMatch()
      this.boardShow = true
      this.buttonShow = false
      this.$refs.tabs.noTransition = false
    },
    getTeams() {
      this.loading = true
      this.db.ref('team').orderByKey().get().then(teams => {
        if (teams.exists()) {
          this.teams = teams.val()
          const records = Object.keys(this.teams)
          if (records.length > 0) {
            this.current = records[records.length - 1]
            this.team = this.teams[this.current]
            this.$nextTick(() => {
              this.$refs.teamA.forEach(el => {
                el.$el.classList.remove('start')
                el.$el.classList.add('loaded')
              })
              this.$refs.teamB.forEach(el => {
                el.$el.classList.remove('start')
                el.$el.classList.add('loaded')
              })
            })
            const arr = []
            records.forEach(match => {
              console.log('push', match)
              arr.push(match)
              console.log('call getScore')
              this.getScore(match)
            })
            this.matches = arr
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getScore(matchId) {
      this.loading = true
      this.score[matchId] = zipObject(this.users, this.users.map(() => {
        return null
      }))
      this.db.ref(`score/${matchId}`).get().then(score => {
        if (score.exists()) {
          console.log(matchId, score.val(), 'getScore')
          this.score[matchId] = merge(this.score[matchId], score.val())
          console.log(this.score[matchId])
          this.tab = this.matches[this.matches.length - 1]
          this.boardShow = true
          this.$refs.tabs.noTransition = false
          this.$nextTick(() => {
            setTimeout(() => {
              this.loading = false
            }, 100)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    inputUsers(matchId) {
      if (this.inputSorted) {
        const users = []
        // const absentee = [...this.absentee]
        // console.log(absentee)
        this.users.forEach((user, index) => {
          if (index % 2 === 0) {
            users.push(this.teams[matchId].A[index / 2])
          } else {
            users.push(this.teams[matchId].B[index / 2 - 0.5])
          }
        })
        return users
      } else {
        return this.users
      }
    },
    updateScore(index, user) {
      // console.log(index, user, 'updateScore')
      if (this.score[index][user]) this.db.ref(`score/${index}/${user}`).set(parseInt(this.score[index][user]))
      this.reRender = true
      this.$nextTick(() => {
        this.reRender = false
      })
    },
    addMatch() {
      this.current = Date.now()
      this.db.ref('team/' + this.current).set(this.team)
      this.getTeams()
      this.getScore(this.current)
      this.tab = this.matches[this.matches.length - 1]
      console.log('Match added')
    },
    matchDate(date) {
      const formatted = new Date(parseInt(date))
      return `${formatted.getMonth() + 1}/${formatted.getDate()}`
    },
    total(matchId, team = null) {
      const member = team ? this.teams[matchId][team] : this.users
      return reduce(member, (sum, n) => {
        return sum + parseInt(this.score[matchId][n] || 0)
      }, 0) || '-'
    },
    average(matchId, team = null) {
      const members = (team ? this.teams[matchId][team] : this.users).length
      return this.total(matchId, team) / members || '-'
    },
    deleteMatch(matchId) {
      // this.confirm[matchId] = true
      if (window.confirm('해당 매치를 삭제하겠습니까?')) {
        this.boardShow = false
        this.db.ref('team/' + matchId).set(null)
        this.db.ref('score/' + matchId).set(null)
        this.matches.splice(this.matches.indexOf(matchId), 1)
        this.tab = this.matches[this.matches.length - 1]
        this.boardShow = true
      }
    },
    rullet(user) {
      let users = without(this.users, ...[])
      let back = users.splice(0, users.indexOf(user))
      users = [...users, ...back, ...users, ...back]
      return `<div><span>${users.join('</span><span>')}</span></div>`
    },
    animate(callback) {
      let count = ([...this.team.A, ...this.team.B]).length
      let order = 1
      let duration = 2000
      document.querySelectorAll('.rullet').forEach(el => {
        el.classList.remove('loaded')
        el.classList.remove('start')
      })
      this.$nextTick(() => {
        this.interval = setInterval(() => {
          let current = order++
          let node = null
          if (count < order - 1) {
            clearInterval(this.interval)
            callback()
            return
          }
          console.log(current)
          if (current % 2 === 1) {
            node = this.$refs.teamA[(current / 2) - 0.5]
          } else {
            node = this.$refs.teamB[(current / 2) - 1]
          }
          node.$el.childNodes[0].style.animationDuration = duration + 'ms'
          node.$el.style.animationDuration = duration + 'ms'
          node.$el.classList.add('start')
        }, duration * 0.9)
      })
    },
    viewReport(user) {
      const data = []
      const axis = []
      let total = 0
      let absenteeGames = 0
      this.report.user = user
      this.report.winCount = 0
      this.matches.forEach(matchId => {
        const formatted = new Date(parseInt(matchId))
        const team = findKey(this.teams[matchId], (o) => {
          return o.includes(user)
        })
        const teamAverage = {
          A: reduce(this.teams[matchId]["A"], (sum, user) => {
            return sum += parseInt(this.score[matchId][user])
          }, 0) / this.teams[matchId]["A"].length,
          B: reduce(this.teams[matchId]["B"], (sum, user) => {
            return sum += parseInt(this.score[matchId][user])
          }, 0) / this.teams[matchId]["B"].length
        }
        this.report.winCount += sum(values(teamAverage)) / 2 < teamAverage[team] ? 1 : 0
        if(!team) absenteeGames++
        total += this.score[matchId][user]
        axis.push(`${formatted.getMonth() + 1}/${formatted.getDate()}`)
        data.push(this.score[matchId][user])
      })
      this.avg = total / (this.matches.length - absenteeGames)
      this.report.option.annotations.yaxis[0].y = this.avg
      this.report.option.xaxis.categories = axis
      this.report.series[0].data = data
      this.report.show = true
    }
  },
  filters: {
    decimal(value) {
      return value > 0 ? value.toFixed(2) : '-'
    }
  }
}
</script>

<style lang="scss">
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("default", (
  primary: md-get-palette-color(gray, A400), // The primary color of your application
  accent: md-get-palette-color(red, A200) // The accent or secondary color
));

@import "~vue-material/dist/theme/all"; // Apply the theme

#app {
  font-family: 'NanumSquare', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .md-chip + .md-chip {
    margin-left: 0;
  }

  .md-chip {
    margin: 2px;
  }
}

.md-card-content:last-of-type {
  padding-bottom: 0;
}

.md-app {
  //max-height: 100vh;
  //border: 1px solid rgba(#000, .12);
}

.md-field {

}

.md-app-container {
  overflow-x: hidden;
}

.md-app-toolbar {
  //height: 196px;
}

.md-app-content {
  padding: 16px 0;
  background-color: #eee;
}

.md-content.md-theme-default {
  background-color: #eee;
}

.md-toolbar {
  padding: 0 14px;
  .md-toolbar-offset {
    margin-left: 0;
  }
}

.md-button {
  width: 100%;
  min-height: 50px;
}

.teams {
  width: 100%;
  margin: 0 16px 12px;
}

.team {
  margin: 10px 0;
  display: flex;
  height: 36px;

  label {
    margin-right: 10px;
    font-weight: bold;
    line-height: 37px;
  }

  .md-chip {
    color: #fff;
    font-weight: bold;
  }

  .team-a, .team-a span {
    background-color: #559aad;
  }

  .team-b, .team-b span {
    background-color: #d68e1a;
  }
}

.add-button {
  width: auto;
  min-height: 0;
}

.scores {
  padding: 0 4px;
  .md-content {

  }
}

.md-tabs {
  width: 100%;

  .md-tab {
    padding: 0;
    position: relative;

    .md-title {
      margin-top: 20px;
    }

    .md-layout {
      display: flex;
      flex-wrap: wrap;
      padding: 4px 16px 16px;
      //padding: 0;
    }

    .team-a, .team-b {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -7px;
        right: -7px;
        top: -4px;
        bottom: -9px;
        border-radius: 4px;
        display: block;
        opacity: 0.2;
      }

      &::after {
        position: absolute;
        top: 3px;
        left: -4px;
        margin-left: 0.5em;
        font-size: 9px;
        font-weight: bold;
        line-height: 9px;
        padding: 4px 4px 3px;
        color: #fff;
        border-radius: 2px;
      }
    }

    .team-a {
      &::before {
        background-color: #559aad;
      }

      &::after {
        content: "team A";
        background-color: #559aad;
      }
    }

    .team-b {
      &::before {
        background-color: #d68e1a;
      }

      &::after {
        content: "team B";
        background-color: #d68e1a;
      }
    }
  }
}

.md-tabs-navigation {
  box-shadow: 0 4px 10px rgba(0, 0, 0, .05);

  .md-button {
    min-width: 10px;

    &.md-active {
      &::before {
        display: none;
      }
    }
  }

  .md-ripple {
    padding: 0 4px;
  }
}

.md-tabs-content {
  background-color: #fff !important;

  .field-wrap {
    width: calc(50% - 24px);
    margin: 12px;
  }

  .md-field {
    margin-bottom: 0;

    label {
      left: 0;
      top: 21px;
      right: auto;
      font-size: 16px;
    }

    &.md-has-value label {
      font-size: 16px;
      color: #000;
    }
  }

  .md-input {
    width: 100%;
    height: 42px;
    margin-top: -10px;
    padding-left: 2px;
    padding-bottom: 6px;
    font-size: 37px !important;
    text-align: right;

    &:valid {
      font-weight: bold;
    }
  }
}

.summary {
  width: 100%;
  display: flex;
  flex-flow: wrap;

  dl, dt, dd {
    padding: 0;
    margin: 0;
    width: auto;
  }

  dl {
  }

  &-col {
    flex: 1 1 auto;
    display: flex;
    text-align: left;
    width: 100%;
    flex-wrap: wrap;
    margin-top: -8px;

    strong {
      padding: 4px;
      flex: 1 1 auto;
      margin: 5px 5px 16px !important;
      padding: 9px 7px 7px;
      font-size: 12px;
      background-color: #eee;
      line-height: 12px;
      border-radius: 4px;
      color: #fff;
      font-weight: bold;

      &.team-title-a {
        background-color: #559aad;
      }

      &.team-title-b {
        background-color: #d68e1a;
      }
    }
  }

  &-item {
    flex: 1 0 20%;
    margin: 5px;
    margin-top: 3px;
    padding: 7px;
    background-color: #eee;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;

    &.half {
      margin-top: 22px;
    }

    > span {
      display: block;
      margin-top: 5px;
      font-size: 18px;
      line-height: 18px;
      text-align: right;
      font-weight: bold;
      flex: 1 1 auto;
      letter-spacing: -0.03em;
    }
  }
}

.sort {
  width: 50px;
  position: absolute;
  left: 5px;
  top: 18px;
  min-height: 0;
}

.delete {
  width: 50px;
  position: absolute;
  right: 3px;
  top: 18px;
  min-height: 0;
}

.rullet {
  overflow: hidden;

  div {
    transform: rotateX(360deg);
    @for $i from 0 through 19 {
      span:nth-child(#{$i + 1}) {
        transform: rotateX(18 * $i * 1deg) translateZ(90px);
      }
    }
  }

  &.start {
    animation: rullet-chip 1s;

    div {
      opacity: 1;
      animation: rullet 1s;
    }
  }

  &.loaded {
    div {
      opacity: 1;
      animation: none;
    }
  }
}

.rullet div {
  width: 36px;
  display: block;
  transform-style: preserve-3d;
  animation-timing-function: ease-out;
  opacity: 0;

  span {
    display: block;
    position: absolute;
  }
}

@keyframes rullet-chip {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 white;
  }
  30% {
    transform: scale(1.6);
    box-shadow: 0 0 10px white;
    z-index: 1;
  }
  95% {
    transform: scale(1.6);
    box-shadow: 0 0 10px white;
    z-index: 1;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 white;
  }
}

@keyframes rullet {
  0% {
    transform: rotateX(-30deg);
  }
  75% {
    transform: rotateX(360deg);
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
}

.win-count {
  position: absolute;
  right: 24px;
  top: 4px;
  font-size: 37px;
  letter-spacing: -0.3em;
  span {
    font-size: 20px;
    font-family: 'NanumSquare';
    font-weight: normal;
    letter-spacing: 0;
    margin-left: 0.5em;
  }
}

.md-dialog {
  transition-duration: 0s;
}

.md-dialog-container {
  max-width: calc(100% - 24px);
  max-height: 500px;
  position: relative;
}

.md-dialog-title {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.03em;
  line-height: 1.2;
  margin-left: -0.05em
}

.md-dialog-content {
  .trendline {
    margin: 0 -12px;
  }

  svg {
    overflow: visible;
  }

  .average {
    position: relative;
    margin-top: 0;
    dt {
      text-transform: uppercase;
    }

    dd {
      text-align: right;
      font-size: 60px;
      letter-spacing: -0.05em;
      margin-top: -0.05em;
    }
  }
}
</style>
