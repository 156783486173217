import Vue from 'vue'
import VueMaterial from 'vue-material'
import VueApexCharts from 'vue-apexcharts'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import App from './App.vue'

import firebase from 'firebase'

Vue.use(VueMaterial)
Vue.use(VueApexCharts)

Vue.config.productionTip = false

var config = {
  apiKey: "AIzaSyAe2remrirXALA-vUn_LWFvspyF-3oc_7Y",
  authDomain: "dfluid-bowling.firebaseapp.com",
  projectId: "dfluid-bowling",
  databaseURL: "https://dfluid-bowling-default-rtdb.firebaseio.com/",
  storageBucket: "dfluid-bowling.appspot.com",
  messagingSenderId: "775197404400",
  appId: "1:775197404400:web:73d6e60b69060657952635"
}
firebase.initializeApp(config)

new Vue({
  render: h => h(App),
}).$mount('#app')
